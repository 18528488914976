export const defaultColumns = [
  {
    title: '名稱',
     ellipsis: true,
    dataIndex: 'name'
  },

  {
    title: '發文字號', 
    ellipsis: true,
    dataIndex: 'documentNumber'
  },

  {
    title: '文件類型',
    width: '150px',
    dataIndex: 'fileType',
    scopedSlots: { customRender: 'fileType' }
  },
  {
    title: '緊急程度',
    width: '150px',
    dataIndex: 'urgencyDegree',
    scopedSlots: { customRender: 'urgencyDegree' }
  },
  {
    title: '密級程度',
    width: '150px',
    dataIndex: 'secrecyDegree',
    scopedSlots: { customRender: 'secrecyDegree' }
  },

  {
    title: '擬稿單位/人',
    width: '150px',
    dataIndex: 'draftId',
    scopedSlots: { customRender: 'draftId' }
  },

  {
    title: '主送',
    width: '150px',
    dataIndex: 'mainIds',
    scopedSlots: { customRender: 'mainIds' }
  },

  {
    title: '抄送',
    width: '150px',
    dataIndex: 'sendIds',
    scopedSlots: { customRender: 'sendIds' }
  },

  {
    title: '擬稿日期',
    width: '150px',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    title: '狀態',
    width: '150px',
    dataIndex: 'approvalStatus',
    scopedSlots: { customRender: 'approvalStatus' }
  },
  {
    title: '操作',
    // width: '150px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
